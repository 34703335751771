import React, { useState } from 'react';
import Modal from 'react-modal';
import "./assets/styles/_index.scss"

const DownloadFileModal = ({ isOpen, onRequestClose, files }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const modalStyles = {
    overlay: {
      backgroundColor: '#00000050',
      zIndex: '9999',
    },
    
    content: {
      width: '30%',
      height: '30%', 
      backgroundColor: '#ffffff',
      border:'0px solid',
      borderRadius: '10px',
      margin: '0 auto',
      top: '20%'
    }
  };

  const listStyle = {
    cursor:"pointer"
  }

  const handleFileSelect = (file) => {
  //  window.open(file, '_blank');

    setSelectedFile(file);
  };

  const renderFilePreview = () => {

    if (!selectedFile) return null;

    const { type, url } = selectedFile;

    if (type === 'pdf') {
    //  window.open(url, '_blank');
      
      const imageUrl = url
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Project.pdf';
      anchor.click();

    } else if (type === 'video') {
      //window.open(url, '_blank');
     

      const imageUrl = url
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Project.mp4';
      anchor.click();
    }else if (type === 'jpeg') {
      //window.open(url, '_blank');
     

      const imageUrl = url
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Project.jpeg';
      anchor.click();
    }else if (type === 'png') {
      //window.open(url, '_blank');
     

      const imageUrl = url
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Project.png';
      anchor.click();
    }else if (type === 'jpg') {
      //window.open(url, '_blank');
     

      const imageUrl = url
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Project.jpg';
      anchor.click();
    }

    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File Modal"
      className="modalfilemodal"
      style={modalStyles} 
    >
      <div className='header-file'>
      {/* <h2>Select a File</h2> */}
      <button className='header-file-button' onClick={onRequestClose}>X</button>
      </div>
      <ul>
        {files.map((file, index) => (
          <li key={index} onClick={() => handleFileSelect(file)} style={listStyle}>
            {file.name}
          </li>
        ))}
      </ul>
      {renderFilePreview()}
    </Modal>
  );
};

export default DownloadFileModal;
